<template>
  <div
    class="border border-gray-200 shadow break-avoid print:shadow-none print:border-none"
    :class="bgColor"
  >
    <slot name="card-header">
      <CardHeader v-if="cardHeader">
        <h3 class="text-base">{{ cardHeader }}</h3>
      </CardHeader>
    </slot>

    <slot>
      <div v-if="cardBody" class="card-body print:p-0">{{ cardBody }}</div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    cardHeader: {
      type: String,
      default: ''
    },
    cardBody: {
      type: String,
      default: ''
    },
    imageSource: {
      type: String,
      default: ''
    },
    borderColor: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: 'bg-white'
    }
  }
}
</script>
